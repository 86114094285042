<template>
  <b-modal id="modal-add-edit-sub-region" size="md" :no-close-on-backdrop="true" :no-close-on-esc="true" v-model="mostrarModal" dialog-class="modal-dialog-scrollable" v-if="mostrarModal" @close="handleModalClose">
    <template slot="modal-title">
      <div>
        <span class="font-weight-light"><i class="fa fa-fw fa-map-marker-alt"></i> {{ subRegionId ? "Editar" : "Agregar" }} sub región </span>
      </div>
    </template>
    <b-form id="frm-add-edit-sub-region">
      <div class="row pt-1">
        <div class="col-md-12">
          <label class="font-weight-bold"> Región</label>
          <select class="form-control" v-model="subRegion.regionId">
            <option :value="null">- Seleccionar -</option>
            <option v-for="region in regiones" :value="region.regionId" :key="region.regionId">{{ region.nombre }}</option>
          </select>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Nombre</label>
          <b-form-input id="input-2" v-model="subRegion.nombre" required placeholder=""></b-form-input>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Orden</label>
          <b-form-input id="input-3" v-model="subRegion.orden" required placeholder=""></b-form-input>
        </div>
      </div>
    </b-form>
    <template slot="modal-footer">
      <button type="button" class="btn btn-secondary" @click.prevent="handleModalClose()"><i class="fa fa-ban"></i> Cancelar</button>
      <button type="button" class="btn btn-primary font-weight-bold" @click.prevent="doSave()"><i class="fa fa-save"></i> Guardar</button>
    </template>
  </b-modal>
</template>

<style></style>

<script>
export default {
  name: "add-edit-sub-region",
  components: {},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    subRegionId: {
      type: Number,
    },
  },
  data: () => ({
    mostrarModal: true,
    subRegion: {
      subRegionId: null,
      regionId: null,
      nombre: "",
      orden: null,
    },
    regiones: [],
  }),
  computed: {},
  methods: {
    handleModalClose: async function(event) {
      if (event) {
        event.preventDefault();
      }

      /*var resp = await this.sweetConfirm("¿Desea cerrar esta ventana?");
      if (resp) {
        this.mostrarModal = false;
      }*/

      this.mostrarModal = false;
    },
    doSave: async function() {
      debugger;
      if (!(await this.sweetConfirm("¿Desea guardar los datos?"))) {
        return;
      }

      if (!this.isFormValid("frm-add-edit-sub-region")) {
        return this.sweetAlert("Hay campos con valores incorrectos. Revise los datos ingresados.");
      }

      var model = {
        ...this.subRegion,
      };

      this.estaGuardando = true;
      await this.$http.post(this.apiRoute("Region", "AddEditSubRegion"), model).then(function(response) {
        if (!this.procesarRespuestaErrorDefecto(response)) {
          this.sweetAlert(response.body.message, "success");
        }
      }, this.mostrarMensajeExcepcionVueResource);

      this.estaGuardando = false;
      this.mostrarModal = false;
    },
    fetchSubRegion: function(subRegionId) {
      if (subRegionId) {
        this.$http
          .get(this.apiRoute("Region", "GetSubRegionById"), {
            params: {
              SubRegionId: subRegionId,
            },
          })
          .then(function(response) {
            this.subRegion = response.body.data;
          });
      }
    },
    fetchRegiones: function() {
      var modelRegion = {};

      this.$http.post(this.apiRoute("Region", "ListRegiones"), modelRegion).then(function(response) {
        this.regiones = response.body.data.items;
      });
    },
  },
  watch: {
    mostrarModal: function(newValue) {
      this.$emit("input", newValue);
    },
  },
  mounted: function() {
    this.mostrarModal = this.value;
    this.subRegion.subRegionId = this.subRegionId;
    this.initParsley();
    this.fetchRegiones();
    this.fetchSubRegion(this.subRegion.subRegionId);
  },
  destoyed: function() {},
};
</script>
